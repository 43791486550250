var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-center fs-medium"},[_vm._v(" "+_vm._s(_vm.$t('components.WelcomeForm.fillUpInputs'))+" ")]),_c('form',{attrs:{"id":"welcome-form","autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('D24Input',{staticClass:"mb-3",attrs:{"name":"login","model":_vm._model.email,"autocomplete":"username","required":true,"label":_vm.$t('_.email-address'),"errors":_vm.errors.email},on:{"update:model":function($event){return _vm.$set(_vm._model, "email", $event)}}}),_c('D24Input',{staticClass:"mb-3",attrs:{"name":"password","model":_vm._model.password,"id":"new-password","type":"password","autocomplete":"new-password","required":true,"label":_vm.$t('_.password'),"errors":_vm.errors.password},on:{"update:model":function($event){return _vm.$set(_vm._model, "password", $event)}}}),_c('D24Input',{staticClass:"mb-3",attrs:{"name":"password","model":_vm._model.passwordConfirmation,"type":"password","autocomplete":"new-password","required":true,"label":_vm.$t('_.password-confirmation'),"errors":_vm.errors.passwordConfirmation},on:{"update:model":function($event){return _vm.$set(_vm._model, "passwordConfirmation", $event)}}}),_c('D24Check',{staticClass:"mb-2",attrs:{"name":"agreement","model":_vm._model.agreement,"options":[{
                label: _vm.agreementText,
                value: true
              }],"multiple":true,"required":true},on:{"update:model":function($event){return _vm.$set(_vm._model, "agreement", $event)}}}),_c('D24Check',{attrs:{"name":"newsletter","model":_vm._model.newsletter,"options":[{
                label: _vm.newsletterText,
                value: true,
                readMore: true,
              }],"multiple":true,"required":false},on:{"update:model":function($event){return _vm.$set(_vm._model, "newsletter", $event)}}}),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-around mt-6"},[_c('Socials',{on:{"onSocialSelect":_vm.handleOnSocialSelect}}),_c('D24Button',{staticClass:"order-md-1",class:_vm.registerButtonClassName,attrs:{"type":"submit","form":"welcome-form"}},[_vm._v(" "+_vm._s(_vm.$t('_.next'))+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }